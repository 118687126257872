<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="80vw" top="10vh">
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="!selection.length" type="primary" @click="confirmForm">{{selection.length ? `关联已选（${selection.length}）项` : '请选择关联项'}}</el-button>
    </span>

    <el-form style="margin-bottom: 15px;" ref="searchForm" class="form-search" :model="searchForm" inline>
      <el-form-item>
        <el-input v-model="searchForm.keyword_meta" placeholder="元数据" clearable style="width: 200px;" />
      </el-form-item>
      <el-form-item>
        <el-input v-model="searchForm.keyword_meta_model" placeholder="元模型" clearable style="width: 200px;" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="refresh">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData" @selection-change="selectionChange" border height="calc(100vh - 400px)"
      :default-sort="tableSort"
      @sort-change="sortChange"
    >
      <el-table-column type="selection" width="39" />
      <el-table-column prop="code" label="元数据编号" width="180" />
      <el-table-column prop="name" label="元数据名称" min-width="180" show-overflow-tooltip />
      <el-table-column label="元数据类型" width="180">
        <template slot-scope="{row}">
          <span v-if="row.model_id">{{row.model_name}}({{row.model_code}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180" />
      <el-table-column prop="update_time" label="更新时间" width="180" />
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tableSort: { prop: 'id', order: 'descending' },

      searchForm: {},

      selection: [],
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
  },
  methods: {
    async init() {
      this.refresh();
    },

    async refresh() {
      console.log('refresh')
      try {
        this.loading = true;
        const res = await this.$api.execute('meta.list', _.assign({}, this.searchForm, _.pick(this, ['current', 'pageSize', 'tableSort'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    sortChange({ prop, order }) {
      this.tableSort = order ? { prop, order } : null;
    },

    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.selection = [];
      this.init();
      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('data_qa.add_meta', this.form.id, _.map(this.selection, 'id'));
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        console.log(e)
        this.$message.error(e.message);
      }
    },

    selectionChange(selection) {
      this.selection = selection;
    },
  }
}
</script>