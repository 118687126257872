<template>
  <div>
    <div style="margin-bottom: 15px;">
      <el-button type="primary" @click="createRecord">添加元数据</el-button>
      <el-button :disabled="!selection.length" type="primary" @click="removeSelection">删除选择</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 300px)"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column prop="meta_name" label="元数据名称" width="180" />
      <el-table-column prop="meta_code" label="元数据代码" min-width="180" show-overflow-tooltip />
      <el-table-column label="元数据类型" width="180">
        <template slot-scope="{row}">
          <span v-if="row.meta_model_id">{{row.meta_model_name}}({{row.meta_model_code}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="options" label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <select-form ref="selectForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import SelectForm from './SelectForm';
export default {
  components: { SelectForm },
  props: { node: Object },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      selection: [],
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    node() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 

  methods: {
    async refresh() {
      if(!this.node) return;
      console.log('refresh')
      this.loading = true;
      const res = await this.$api.execute('data_qa.list_meta', this.node.id, _.assign({}, _.pick(this, ['current', 'pageSize'])));
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },

    createRecord() {
      this.$refs.selectForm.show({ id: this.node.id }, { title: '关联元数据', success: () => {
        this.refresh();
      } });
    },
    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将元数据从列表中移除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('data_qa.remove_meta', this.node.id, [obj.meta_id])
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e === 'cancel') { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    async removeSelection() {
      try {
        await this.$confirm(`此操作将（${this.selection.length}条）元数据从列表中移除, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('data_qa.remove_meta', this.node.id, _.map(this.selection, 'meta_id'))
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    selectionChange(selection) {
      this.selection = selection;
    },
  }
}
</script>