<template>
  <div>
    <el-tabs v-model="tabName" @tab-remove="tabRemove">
      <el-tab-pane label="关联元数据" name="pane1">
        <pane1 ref="pane1" :node="node" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Pane1 from './Pane1';
export default {
  components: { Pane1 },
  data() {
    return {
      tabName: 'pane1',
      loading: false,

      node: null,
    }
  },
  computed: {
    ...mapState(['tabs'])
  },
  watch: {
    tabName(value) {
      this.$refs[value] && this.$refs[value].refresh && this.$refs[value].refresh();
    }
  },
  created() {
    this.$store.commit('cleanTabs');
    this.refresh()
  },
  methods: {
    async refresh() {
      const { id } = this.$route.params;
      try {
        this.loading = true;
        this.$store.commit('setDataQa', null);
        const { list: [node]} = await this.$api.execute('data_qa.list', { id });
        this.$store.commit('setDataQa', node);
        this.node = node;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    viewItem(e) {
      this.tabName = e;
    },

    tabRemove(e) {
      console.log('tabRemove', e, this.tabs)
      if(this.tabName === e) {
        const index = _.findIndex(this.tabs, { label: e });
        const next = this.tabs[index + 1] || this.tabs[index - 1];
        this.tabName = next ? next.label : 'pane2';
      }
      this.$store.commit('removeTab', e);
    }
  }
}
</script>